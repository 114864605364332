import React, { useState, useEffect } from 'react';
import { client } from "../apollo/client";
import { gql } from 'apollo-boost';
import { Location } from "@reach/router"
import NodeArticle from "../templates/node-article";
import NodePage from "../templates/node-page";
import queryString from 'query-string'

const Preview = () => {

  const [nid,setNid] = useState();
  const [articleContent, setArticleContent] = useState();

  useEffect(() => {
    async function fetchData() {
      await client.query({
        query: gql`
        {
          nodeById(id:"${nid}"){
            entityBundle
            entityId
            status
            entityMetatags {
                key
                value
                __typename
            }
            entityUrl {
                path
            }
            ... on NodePage{
              entityMetatags{
                  key
                  value
                  __typename
              }
              entityUrl{
                ...on EntityCanonicalUrl{
                    breadcrumb{
                      text
                      url{
                        path
                      }
                    }
                }
                path
              }
              title
              body {
                  processed
              }
              fieldParagraphs{
                targetId
              }
            }
            ... on NodeArticle{
              entityMetatags{
                  key
                  value
                  __typename
              }
              entityUrl{
                ...on EntityCanonicalUrl{
                    breadcrumb{
                      text
                      url{
                        path
                      }
                    }
                }
                path
              }
              title
              body {
                  processed
              }
              fieldTags {
                  entity {
                      entityLabel
                      entityUrl {
                          path
                      }
                  }
              }
              fieldImage {
                  url
              }
              entityUrl {
                  path
              }
            }
          }
        }
      `
      }).then(result => setArticleContent(result.data))
    }
    if(nid > 0){
      fetchData()
    }
  },[nid])

  return(
     <Location>
        {
          ({ location }) => {
            setNid(queryString.parse(location.search).nid)
            if(articleContent){
              if(articleContent.nodeById.entityBundle === 'article'){
                return <NodeArticle
                  pageContext={{article: articleContent.nodeById}}
                  preview
                />
              }
              if(articleContent.nodeById.entityBundle === 'page'){
                return <NodePage
                  pageContext={{page: articleContent.nodeById}}
                  preview
                />
              }
            }

          }
        }
      </Location>
  )
}

export default Preview;
